/*
 * @Author: your name
 * @Date: 2022-04-28 14:24:33
 * @LastEditTime: 2022-05-06 17:35:50
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\pages\phoDetail\useSwiperPhoto.js
 */
import {
  computed,
  onActivated,
  toRefs,
  onDeactivated,
  onMounted,
  ref,
} from 'vue'
import { getImgDetail } from '@/api/api.js'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
export default function () {
  let store = useStore()
  let { params } = useRoute()
  let router = useRouter()
  let photoData = ref({
    img: [
      {
        children: [],
      },
    ],
    user: {
      id: undefined,
      avatar: ' ',
    },
  })
  let imgArr = computed(() => {
    let _photoData = photoData.value
    let arr = []
    if (_photoData.length != 0) {
      _photoData.img[0].children.forEach(item => {
        arr.push({
          src: item.imgurl,
        })
      })
    }
    return arr
  })

  
  onMounted(async () => {
    try {
      let { data } = await getImgDetail({ id: params.id })
      photoData.value = data
    } catch (error) {
      console.log(error)

      router.replace('/404')
    }
  })

  return {
    imgArr,
    photoData,
    params,
    store,
  }
}
