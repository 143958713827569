<!--
 * @Author: your name
 * @Date: 2022-04-28 13:36:43
 * @LastEditTime: 2022-05-07 19:55:32
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AEvs
 * @FilePath: \MyBoke\src\pages\phoDetail\index.vue
-->
<template>
  <div class="phoDetail">
    <div class="userInfo">
      <div class="avatarImg">
        <img
          :src="photoData?.user?.avatar"
          alt=""
          @click="$router.push({ path: `/user/${photoData?.user?.id}` })"
        />
      </div>
      <div class="usernameInfo">
        <div class="name">
          <span>{{ photoData.user?.username }}</span>
          <el-button
            type="text"
            v-if="!photoData.isFollowed"
            @click="createFollowFn(photoData.user?.id)"
            >关注</el-button
          >
          <el-button
            type="text"
            v-else
            style="color: var(--white_divFc_light)"
            @click="createFollowFn(photoData.user?.id)"
            >已关注</el-button
          >
        </div>
        <div class="time">
          {{ photoData?.created_at }}
        </div>
      </div>
    </div>
    <SwiperIndexVue
      :height="flexScreen == 'mob' ? 250 : 500"
      :imgArr="imgArr"
      :isPhoto="true"
    />
    <div class="content">
      <div class="imgTitle">
        <span>{{ photoData.title }}</span>
      </div>
      <div class="desription">
        <span
          v-for="(item, index) in photoData.tag_name?.split(',')"
          :key="index"
          >#{{ item }}
        </span>
      </div>
      <div class="imgTool">
        <ArticleToolVue
          class="ArticleToolVue"
          :likeNum="photoData.likeNum"
          :commentNum="photoData.commentNum"
          :isLikedMe="photoData.isLiked"
          @pingFn="commentFlag = !commentFlag"
          @likeFn="likeFn"
        />
        <!-- <div class="readNum">阅读:{{ photoData.readNum }}</div> -->
        <div class="CommentEditVue">
          <CommentEditVue
            v-show="commentFlag"
            @submitFun="submitFun"
            :placeholder="placeholder"
          >
            <template #welcome> 欢迎评论，请友好发言！ </template>
          </CommentEditVue>
        </div>
      </div>
      <div class="commentList">
        <div class="commentTitle">
          <span>最新评论</span>
        </div>
        <!-- 评论区 -->
        <div class="replyDiv">
          <!-- 评论列表 -->
          <Reply
            class="Reply"
            v-for="comment in comments"
            :key="comment.id"
            :comment="comment"
            @reply="onReply"
            @spread="onSpread"
            :isComment="true"
            :ref="pushRef"
          >
            <!-- 楼中楼 -->
            <template #reply>
              <div
                class="ReplyItem"
                v-if="replys[comment.id] && replys[comment.id].status"
              >
                <Reply
                  class="Reply"
                  v-for="(reply, index) in replys[comment.id].data"
                  @reply="onReply"
                  :comment="reply"
                  :key="index"
                  :isComment="false"
                >
                </Reply>
              </div>
            </template>
          </Reply>
        </div>
        <div class="loadMore">
          <el-button
            v-if="moreFlag"
            size="small"
            type="default"
            @click="getMoreComments()"
            :loading="moreLoading"
          >
            加载更多
          </el-button>
          <div class="withoutEmpty" v-show="!comments.length">
            -- 期待你的精彩评论 --
          </div>

          <div class="without" v-show="withoutFlag && comments.length">
            -- 就这么多咯 --
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SwiperIndexVue from "@/components/swiperIndex/swiperIndex.vue";
import ArticleToolVue from "@/components/articleTool/articleTool.vue";
import CommentEditVue from "@/components/commentEdit/commentEdit.vue";
import Reply from "@/components/reply/reply.vue";
import useReply from "@/pages/posts/useReply.js";
import { useGetters } from "@/store/utils/useGetters.js";
import useLoading from "@/hooks/useLoading.js";
import useScreen from "@/hooks/useScreen";

import {
  like,
  likeCancel,
  createFollow,
  isFollowed,
  isLikedFn,
} from "@/api/api.js";
import {
  computed,
  nextTick,
  onActivated,
  onDeactivated,
  onMounted,
  ref,
  watch,
} from "vue";
import usePhotoData from "./usePhotoData.js";
import { isLogin, updateLoginShow } from "../../utils/user";
import { ElNotification } from "element-plus";
import { debounce } from "@/utils/public.js";
import { useHead } from "@vueuse/head";
let { flexScreen } = useScreen();
let { imgArr, store, photoData, params, Head } = usePhotoData();
let commentFlag = ref(false);

// useReply可能调用的回调函数
let replyFnMethods = {
  // 评论数++
  addImgCommemtFn: () => {
    photoData.value.commentNum++;
  },
  openEdit: () => {
    // 打开评论编辑器
    if (!commentFlag.value) {
      commentFlag.value = true;
    }
  },
};
// 给useReply的回调函数，在指定地方调用某些函数
let replyFn = (type) => {
  replyFnMethods[type] && replyFnMethods[type]();
};
// 获取是否来自评论按钮状态
let { isFromComment, fromCommentId } = useGetters("postInfo", [
  "isFromComment",
  "fromCommentId",
]);

let useReplys = useReply(params.id, 3, replyFn);
let {
  onScrollToElement,
  sleep,
  replyRef,
  scrollReply,
  comments,
  current,
  limit,
  onSpread,
  onReply,
  replys,
  submitFun,
  moreFlag,
  moreLoading,
  getMoreComments,
  withoutFlag,
  pushRef,
  placeholder,
} = { ...useReplys };

// 判断是否来自外部评论按钮跳转
let isFromCommentBtn = async () => {
  console.log(123);
  nextTick(async () => {
    // 是的话直接定位到评论区
    if (isFromComment.value && !fromCommentId.value) {
      //打开评论
      replyFnMethods.openEdit();
      await sleep(10);
      onScrollToElement(
        "comments",
        async () => {
          let input = document
            .getElementById("comments")
            .getElementsByClassName("textarea-only");
          store.commit("postInfo/UP_isFromComment", false);
          await sleep(300);
          input[0].focus();
        },
        true
      );
    } else if (isFromComment.value && fromCommentId.value) {
      // dom更新后打开新增评论的二级评论区
      scrollReply(
        fromCommentId.value[1],
        fromCommentId.value[0],
        replyRef.data
      );
      store.commit("postInfo/UP_fromCommentId", null);
      store.commit("postInfo/UP_isFromComment", false);
    }
  });
};

useLoading(isFromCommentBtn);


// 点赞
let likeFn = () => {
  // 判断是否登陆
  if (!isLogin()) {
    updateLoginShow(true);
    return;
  }
  like({ id: Number(params.id), type: 3 }).then((res) => {
    if (res.msg === "你已点过赞了！") {
      likeCancel({ id: Number(params.id), type: 3 }).then((canRes) => {
        photoData.value.isLiked = false;
        photoData.value.likeNum--;
        store.commit("imgInfo/UP_LIKELIST", {
          type: "reduce",
          value: {
            id: params.id,
            num: photoData.value.likeNum,
          },
        });
      });
      return;
    }
    photoData.value.isLiked = true;
    photoData.value.likeNum++;
    store.commit("imgInfo/UP_LIKELIST", {
      type: "add",
      value: {
        id: params.id,
        num: photoData.value.likeNum,
      },
    });
  });
};

//关注
let createFollowFn = (fid) => {
  // 判断是否登陆
  if (!isLogin()) {
    updateLoginShow(true);
    return;
  }
  debounce(() => {
    createFollow({ fid }).then((res) => {
      console.log(res);
      ElNotification({
        type: "success",
        title: "通知",
        message: res.msg,
        position: "bottom-right",
        offset: 50,
      });
      photoData.value.isFollowed = !photoData.value.isFollowed;
      console.log(photoData.value.isFollowed);
    });
  }, 500);
};

// 监听登陆状态为true的时候获取所有已存在列表的点赞状态
watch(
  () => store.state.userInfo.isLogin,
  async (newValue) => {
    if (newValue) {
      console.log("登陆");
      isFollowed({ fid: photoData.value.user?.id }).then((res) => {
        photoData.value.isFollowed = !!res.data.isFollowed;
        console.log(res);
      });
      isLikedFn({ id: Number(photoData.value.id), type: 3 }).then((res) => {
        photoData.value.isLiked = !!res.data;
      });
    }
  },
  {
    deep: true,
  }
);

onMounted(async () => {
  store.commit("headInfo/UP_ACTIVE_LINK", "");
  window.scrollTo(0, 0);
});

useHead({
  // Can be static or computed
  title: computed(() => (photoData.value.title || "") + "_Baymax日常分享"),
  meta: [
    {
      name: `description`,
      content: computed(() => photoData.value.title),
    },
    {
      name: `keywords`,
      content: computed(
        () => photoData.value.tag_name || photoData.value.title
      ),
    },
  ],
});
</script>

<style lang="scss" scoped >
.loadMore {
  padding-left: 10px;
  margin-top: 5px;
  width: 100%;
  box-sizing: border-box;
  min-height: 40px;
  .el-button {
    padding: 8px !important;
    background-color: var(--white_divBgc) !important;
    color: var(--white_divFc) !important ;
    border: 1px solid var(--white_divBgc) !important;
  }
}
.withoutEmpty {
  padding: 0 0 20px 0;
  width: 100%;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  justify-content: center;
  color: var(--white_divFc_light);
  min-height: 200px;
  align-items: center;
}
.without {
  padding: 0 0 20px 10px;
  color: var(--white_divFc);
  font-size: 14px;
}
.ReplyItem {
  padding: 0px 15px 15px 15px;
}

.Reply {
  margin-top: 25px;
  border-bottom: 1px solid var(--white_divBoc);
}

.Reply:last-child {
  margin-top: 25px;
  border-bottom: none;
}
.userInfo {
  width: 100%;
  height: 30px;
  display: flex;
  padding: 40px 0px;
  align-items: center;
  box-sizing: border-box;
  color: var(--white_divFc);

  div {
    box-sizing: border-box;
  }
  .avatarImg {
    height: 50px;
    width: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    img {
      height: 45px;
      width: 45px;
      border-radius: 50%;
    }
  }
  .usernameInfo {
    height: 50px;
    flex: 1;
    padding-left: 10px;
    .name {
      display: flex;
      align-items: center;
      height: 55%;
      font-size: 18px;
      // border: 1px solid red;
      justify-content: space-between;

      .el-button {
        margin-left: 5px;
      }
    }
    .time {
      display: flex;
      align-items: center;
      font-size: 12px !important;
      height: 50%;
      padding: 0 !important;
      color: var(--white_divFc_light);
    }
  }
}
.phoDetail {
  height: auto;
  width: 35%;
  min-width: 400px;
  margin: 10px auto;
  background-color: var(--white_divBgc);
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

.content {
  .commentList {
    margin-top: 10px;
    .commentTitle {
      padding: 10px 0;
      color: var(--white_divFc);
      font-size: 16px;
      font-weight: 600;
    }
  }

  .imgTool {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 8px;
    padding-bottom: 10px;
    border-bottom: 0.5px dashed var(--white_divFc_light);
    flex-wrap: wrap;

    .ArticleToolVue {
      ::v-deep .like,
      ::v-deep .ping {
        margin-top: 7px;
        padding: 3px 0px !important;
        display: flex;
        margin-right: 10px;
        box-sizing: border-box !important;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent !important;
        span {
          color: var(--white_divFc_light) !important;
        }
      }
    }
    .readNum {
      color: var(--white_divFc_light) !important;
      font-size: 12px;
    }
    .CommentEditVue {
      margin-top: 10px;
      width: 100%;
      justify-self: flex-start;
      ::v-deep .md-content .md-input-wrapper textarea {
        font-size: 2em;
      }
    }
  }
  .desription {
    margin-top: 10px;
    width: 100%;
    box-sizing: border-box !important;
    height: auto;
    display: flex;
    justify-content: flex-start;
    // border: 1px solid var(--white_divBoc);

    span {
      margin: 3px 3px 3px 0;
      color: var(--white_divFc_light);
      font-size: 12px;
      cursor: pointer;
      background-color: var(--white_divBoc);
      display: inline-flex;
      padding: 4px 8px;
      border-radius: 3px;
      align-items: center;
    }
  }

  .imgTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    margin-top: 10px;
    padding: 10px 0 2px 0;
    color: var(--white_divFc);
    span {
      text-align: left;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-weight: 550;
    }
  }
}

@media screen and (max-width: 768px) {
  .avatarImg {
    cursor: none;
  }
  .phoDetail {
    height: auto;
    width: 100% !important;
    min-width: 200px;
    margin: 10px auto;
    background-color: var(--white_divBgc);
    padding: 0 10px;
    box-sizing: border-box;
  }
}
</style>